<template>
  <div class="detail"  >

    <div class="detail-container" :class="{on : pageLoad}">

      <!-- image -->
      <div class="detail-image" @click="onPreviewImage">
        <!-- <div class="detail-cover"></div> -->
        <van-image :src="productData.preview_image" :width="375 / 37.5 + 'rem'" fit="contain" loading-icon="" error-icon="" @load="onPrevImgLoad"></van-image>
      </div>

      <!-- info -->
      <div class="detail-desc">
        <div class="top">
          <p class="no">{{productData.file_number}}</p>
          <h1 class="title">{{productData.workname}}</h1>
          <div class="cue"><i @click="onShowDialog('download')">源文件请至PC官网下载 <van-image :src="require('../../assets/img/icon-download_2.png')" :width="14 / 37.5 + 'rem'" :height="14 / 37.5 + 'rem'" loading-icon="" error-icon=""></van-image></i></div>
          <div class="detail-info">
            <div class="info_left">
              <div class="info">格式 | {{productData.work_form}}</div>
              <div class="info" v-if="productData.work_form == 'PSD'">分辨率 | {{productData.resolving_power}} DPI</div>
              <div class="info">大小 | {{productData.file_size}}</div>
            </div>
            <div class="info_right">
              <div class="info">颜色 | {{productData.color_pattern_name}}</div>
              <div class="info" v-if="productData.work_form == 'PSD'">尺寸 | {{productData.file_width}} x {{productData.file_height}} PX</div>
              <div class="info">授权协议 | <i @click="onPageToCopyright">© CC-BY-NC</i></div>
            </div>
          </div>
        </div>
        <c-image iUrl="line" iWidth="375" iHeight="9"></c-image>
        <div class="bottom" @click="onPageToMe(designer.id)">
          <div class="left">
            <div class="detail-cover"></div>
            <van-image :src="designer.head_img" :width="48 / 37.5 + 'rem'" :height="48 / 37.5 + 'rem'" fit="cover" loading-icon="" error-icon=""></van-image>
          </div>
          <div class="right">
            <div class="right_top">
              <p class="level">Lv.{{designer.user_level}}</p>
              <p class="post">{{designer.user_level_name}}</p>
            </div>
            <div class="name">{{designer.nickname}}</div>
          </div>
        </div>
      </div>

      <div class="detail-content">
      
        <div class="detail-other">
          <!-- ad -->
          <c-poster-detail :link="adList.image_link" :image="adList.object_name" pClass="detail-ad"></c-poster-detail>

          <!-- relate -->
          <div class="detail-relate">
            <div class="title"><i></i>相关搜索</div>
            <div class="list" v-if="relateSearch.length > 0">
              <div class="tag" v-for="(item , index) in relateSearch" :key="index" @click="onPageToProducts(item)">{{item}}</div>
            </div>
          </div>

          <!-- like -->
          <div class="detail-like">
            <div class="title2"><i></i>猜你喜欢</div>
            <div class="list">
              <c-waterfall :list="similarProducts" :winWidth="winWidth"></c-waterfall>
            </div>
          </div>

          <!-- works -->
          <div class="detail-like">
            <div class="title2"><i></i>该设计师的作品</div>
            <div class="list">
              <c-waterfall :list="designerProducts" :winWidth="winWidth"></c-waterfall>
            </div>
          </div>
        </div>
      </div>

      <!-- footer -->
      <div class="detail-footer" :class="{ on: navStatus }">

        <div class="left" @click="onShowDialog('tip_off')">
          <c-image iUrl="icon-report" iWidth="20" iHeight="18"></c-image>
        </div>

        <div class="right">
          <div class="btn1" @click="onShowDialog('share')">
            <c-image iUrl="icon-share" iClass="icon" iWidth="14" iHeight="14" ></c-image>分享
          </div>
          <div class="btn1" v-if="!isCollected" @click="onCollect">
            <c-image iUrl="icon-collect" iClass="icon" iWidth="14" iHeight="14"></c-image>收藏
          </div>
          <div class="btn2" v-else @click="onShowDialog('cancel_dialog')">
            <c-image iUrl="icon-collect" iClass="icon" iWidth="14" iHeight="14"></c-image>已收藏
          </div>
          <div class="btn1" v-if="isCollected" @click="onEditTag">
            <c-image iUrl="icon-tag" iClass="icon" iWidth="14" iHeight="14"></c-image>标签
          </div>
        </div>
      </div>

      <van-overlay :show="showDialog" @click="showDialog = false">
        <!-- dialog cancel collect -->
        <div class="common-dialog" @click.stop v-if="dialogType == 'cancel_dialog'" >
          <div class="icon_close" @click="showDialog = false">
            <c-image iUrl="icon-close_2" iWidth="14" iHeight="14"></c-image>
          </div>

          <c-image iUrl="icon-warn" iClass="icon" iWidth="36" iHeight="36"></c-image>
          <h1>确定取消收藏？</h1>
          <div class="btn" @click="onHideDialog">确定</div>
        </div>

        <!-- dialog share -->
        <div class="common-dialog" @click.stop v-else-if="dialogType == 'share'">
          <div class="icon_close" @click="showDialog = false">
            <c-image iUrl="icon-close_2" iWidth="14" iHeight="14"></c-image>
          </div>

          <c-image iUrl="icon-share_l" iClass="icon" iWidth="36" iHeight="36" ></c-image>
          <h1>立即分享</h1>
          <p>请用浏览器或微信自带的分享功能</p>
        </div>

        <!-- dialog tip off -->
        <div class="common-dialog" @click.stop v-else-if="dialogType == 'tip_off'" >
          <div class="icon_close" @click="showDialog = false">
            <c-image iUrl="icon-close_2" iWidth="14" iHeight="14"></c-image>
          </div>

          <c-image iUrl="icon-tipoff" iClass="icon" iWidth="36" iHeight="36" ></c-image>
          <h1>作品投诉</h1>
          <p class="tipoff">请将作品编号及投诉材料发送至<br /><i>kefu@design006.com</i ><br />平台会尽快处理</p>
        </div>

        <!-- dialog download -->
        <div class="common-dialog" @click.stop v-else-if="dialogType == 'download'" >
          <div class="icon_close" @click="showDialog = false">
            <c-image iUrl="icon-close_2" iWidth="14" iHeight="14"></c-image>
          </div>

          <c-image iUrl="icon-download" iClass="icon" iWidth="36" iHeight="36" ></c-image>
          <h1>源文件下载</h1>
          <p class="tipoff">复制作品链接至享设计PC官网<br /><i>www.design006.com</i ><br />下载作品源文件</p>
          <div class="btn" v-clipboard:copy="isLogin ? `${baseUrl}/detail-${productId}?inviteCode=${inviteCode}` : `${baseUrl}/detail-${productId}`"  v-clipboard:success="onCopyUrl" v-clipboard:error="onCopyError">一键复制</div>
        </div>
      </van-overlay>

      <c-login-coupon ref="cLoginCoupon"></c-login-coupon>
    </div>

      <c-loading :showLoading="showLoading"></c-loading>
  </div>
</template>

<script>
import { Image, Overlay, ImagePreview } from "vant";
import CImage from "../../components/CImage";
import CWaterfall from "../../components/CWaterfall.vue";
import { mapState, mapActions } from "vuex";
import { toastLoading, closeToast, toast, toastSuccess, toastFail } from '../../lib/util';
import CLoginCoupon from '../../components/CLoginCoupon';
import { loginCoupon } from '../../lib/mix/login-coupon';
import { loginThird } from "../../lib/mix/login-third";
import CLoading from "../../components/CLoading.vue"
import CPosterDetail from "../../components/CPosterDetail.vue"
export default {
    components: {
        [Image.name]: Image,
        [Overlay.name]: Overlay,
        [CImage.name]: CImage,
        [CWaterfall.name]: CWaterfall,
        [CLoginCoupon.name]: CLoginCoupon,
        [CLoading.name]:CLoading,
        [CPosterDetail.name]:CPosterDetail
    },
    mixins: [loginCoupon, loginThird],
    data() {
        return {
            pageLoad: false,
            showDialog: false,
            dialogType: "",
            winWidth: window.innerWidth,
            lastScrollTop: 0, //最后滚动高度
            isCollected: false,
            time: null,
            showLoading:false,

            baseUrl: "https://www.design006.com",
            inviteCode: this.$cookies.get('invite_code')
        };
    },
    computed: {
        ...mapState({
            isLogin: state => state.isLogin,
            navStatus: (state) => state.headerStatus,
            productId: (state) => state.product.productId,
            productData: (state) => state.product.prevData,
            designer: (state) => state.product.productData.designer,
            designerProducts: (state) => state.product.productData.designer_works,
            similarProducts: (state) => state.product.productData.similar_works,
            adList:(state)=>state.product.productData.ad_list,
            relateSearch: (state) => state.product.prevData.work_keywords_arr,
            collect: (state) => state.product.productData.collect,
            userData: (state) => state.me.userData,
        }),
    },
    watch: {
        productId(val) {
            this.loadDetail(val).then(() => {
                //如果用户处于登录状态，url末尾加载分享邀请码参数
                if (this.$cookies.isKey('user_id')) {
                    let _inviteCode = this.$cookies.get('invite_code')
                    this.$router.replace({ path: this.$router.path, query: { inviteCode: _inviteCode } })
                }
            });
        },
        collect(val) {
            this.isCollected = val == 1 ? true : false;
        },
    },
    methods: {
        ...mapActions({
            requestCollectWord: "collect/requestCollectWord",
            requestCollectCancel: "collect/requestCollectCancel",
            requestDetail: "product/requestDetail",
            requestDetailPreview: "product/requestDetailPreview"
        }),

        onPageToProducts(val) {
            this.saveHistory(val);
            this.$router.push({
                name: "products",
                params: { keywords: val },
                query: { keywords: val },
            });
        },
        // 存储关键词到cookie
        saveHistory(keywords) {
            // 处理keywords， 转大写/去2边空格/判断特殊词
            keywords = keywords.trim().toUpperCase();

            if(keywords) {
                var searchHistory = this.$cookies.get('searchHistory');
                searchHistory = searchHistory ? JSON.parse(searchHistory) : [];
                // 如果关键词已存在，先移除旧的关键词
                var index = searchHistory.indexOf(keywords);
                if (index !== -1) {
                    searchHistory.splice(index, 1);
                }
                searchHistory.unshift(keywords);
                // 限制搜索历史长度，可以根据需要调整
                if (searchHistory.length > 10) {
                    searchHistory.pop(); // 移除最后一个搜索词
                }
                this.$cookies.set('searchHistory', JSON.stringify(searchHistory),86400*30);
            }
        },
        onPageToMe(id) {
            this.$router.push({
                name: "homepage",
                params: { id: id, clickFrom: "nav", enterType: 'click' },
                query: { tab: 0 }
            });
        },

        onPageToCopyright() {
            this.$router.push({
                name: "copyright",
            });
        },

        onEditTag() {
            this.$router.push({
                name: "collect_product",
                params: { id: this.productData.id, type: "edit" },
            });
        },

        onCopyError() {
            this.showDialog = false;
            toastFail('复制失败')
        },

        onCopyUrl() {
            this.showDialog = false;
            toastSuccess('复制成功')
        },

        onPrevImgLoad() {
            // closeToast();
            this.showLoading=false;
            this.pageLoad = true;
        },

        onPreviewImage() {
            ImagePreview({
                images: [this.productData.preview_image],
                showIndex: false
            })
        },

        onCollect() {
            let _uuid = this.$cookies.isKey("user_id");
            if (_uuid) {
                this.requestCollectWord({ works_id: this.productData.id }).then(() => {
                    this.$router.push({
                        name: "collect_product",
                        params: { id: this.productData.id, type: "add" },
                    });
                });
            } else {
                this.$router.push({
                    name: "login",
                });
            }
        },

        onHideDialog() {
            this.showDialog = false;
            this.requestCollectCancel({ works_id: this.productData.id }).then(() => {
                this.isCollected = false;
            });
        },
        onShowDialog(type) {
            this.dialogType = type;
            this.showDialog = true;
        },
        loadDetail(id) {
            return new Promise(resolve => {
                this.pageLoad = false;

                if (id) {
                    // toastLoading();
                    this.showLoading=true;
                    this.requestDetailPreview({ work_id: id }).then(res => {
                        document.title = res.workname + '_享设计';
                    }).catch(() => {
                        this.$router.replace({ name: "404" });
                    });

                    this.requestDetail({ work_id: id }).then(res => {
                        resolve();
                    })
                }
            })


        },
    },
    created() {
        //不管用户是否登录，有inviteCode参数都保存到cookies （未登录用户注册填写邀请人的邀请码）
        if (this.$route.query.inviteCode) {
            this.$cookies.set('share_invite_code', this.$route.query.inviteCode, 60 * 60 * 24 * 7)
        }

        //如果用户处于登录状态，url末尾加载分享邀请码参数
        if (this.$cookies.isKey('user_id')) {
            let _inviteCode = this.$cookies.get('invite_code')
            if (this.$route.query.new_reg) this.$router.replace({ path: this.$router.path, query: { inviteCode: _inviteCode, new_reg: this.$route.query.new_reg } })
            else this.$router.replace({ path: this.$router.path, query: { inviteCode: _inviteCode } })
        }


        let _id = this.$route.params.id;
        this.loadDetail(_id);
    },
    mounted() { },

    destroyed() { },
};
</script>

<style>
.detail-container{padding:46px 0 0;opacity: 0;}
.detail-container.on{opacity: 1;}
.detail-cover{width:100%;height:100%;position:absolute;left:0;top:0;z-index:100}
.detail-image{width:100%}
.detail-content{padding:0 12px}
.detail-desc{width:100%;background:#fff;border-radius:4px;overflow:hidden}
.detail-desc .top{padding:16px 12px}
.detail-desc .no{font-size:12px;color:#b3b3b3;letter-spacing:normal}
.detail-desc .title{width:100%;font-size:20px;font-weight: normal; color:#2e2e30;-webkit-box-orient: vertical;text-overflow: ellipsis;overflow: hidden;word-break: break-all;display: -webkit-box;-webkit-line-clamp: 2;}
.detail-desc .cue{margin-top:10px; font-size: 0;}
.detail-desc .cue i{font-size:12px;color:#f85659;font-style: normal;}
.detail-desc .cue i .van-image {vertical-align: text-top;}
.detail-info{margin-top:16px; display: flex; justify-content: flex-start; align-items: flex-start;}
.detail-info .info_left{margin-right: 50px;}
.detail-info .info{font-size:12px;color:#b3b3b3;margin-top:5px}
.detail-info .info i{font-style:normal;height: 12px; display: inline-block;}
.detail-desc .bottom{padding:16px 12px;display:flex;justify-content:flex-start;align-items:center}
.detail-desc .left{margin-right:12px; border-radius: 50%; overflow: hidden; background: #ededed;}
.detail-desc .right_top{display:flex;justify-content:flex-start;align-items:center}
.detail-desc .level{font-size:12px;color:#f85659;margin-right:12px}
.detail-desc .post{font-size:12px;color:#b3b3b3}
.detail-desc .name{font-size:14px;color:#2e2e30;margin-top:4px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap; width:250px;}
.detail-other .title{display:flex;justify-content:flex-start;align-items:flex-end;font-size:20px;color:#2e2e30;margin-top:32px;margin-bottom:16px}
.detail-other .title2{display:flex;justify-content:flex-start;align-items:flex-end;font-size:20px;color:#2e2e30;margin-top:20px;margin-bottom:16px}
.detail-other .title i,.detail-other .title2 i{display:inline-block;width:4px;height:20px;background:#f85659;margin-right:12px;position:relative;bottom:2px}
.detail-relate .list{display:flex;justify-content:flex-start;flex-wrap:wrap;align-items:center}
.detail-relate .tag{padding:8px 20px;background:#fff;font-size:14px;color:#2e2e30;border-radius:4px;margin-right:12px;margin-bottom:12px}
.detail-like .list{margin-top:16px;}
.detail-footer{width:100%;height:60px; position:fixed;left:0;bottom:-60px;background:#fff;z-index:100;display:flex;justify-content:space-between;align-items:center;transition:bottom 0.2s ease-out}
.detail-footer.on{bottom:0}
.detail-footer .left{width:44px;height:60px;display:flex;justify-content:center;align-items:center}
.detail-footer .right{display:flex;justify-content:flex-end;align-items:center}
.detail-footer .right .btn1{padding:8px 20px;border-radius:4px;background:#2e2e30;margin-right:12px;font-size:14px;color:#ffffff;display:flex;justify-content:center;align-items:center}
.detail-footer .right .btn2{padding:8px 20px;border-radius:4px;background:#f85659;margin-right:12px;font-size:14px;color:#ffffff;display:flex;justify-content:center;align-items:center}
.detail-footer .right .btn1 .icon,.detail-footer .right .btn2 .icon{margin-right:5px}
.detail-preview{display: none;}
.detail-ad{margin-top: 32px; border-radius: 4px; overflow: hidden;}
</style>